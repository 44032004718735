<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>商户审核详情</span>
      </div>
      <div>
        <el-form :inline="false" class="form" style="text-align: left;" label-width="250px" :model="item">
          <el-tabs v-model="activeName">
            <el-tab-pane label="商户信息" name="merchant">
              <el-form-item label="商家图标" class="form-item">
                <el-image style="width: 50px; height: 50px; border-radius: 50%" :src="item.merLogoUrl">
                  <div slot="error" class="el-image__error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-form-item>
              <el-form-item label="商户名" class="form-item" prop="merName">
                <el-input v-model="item.merName" size="small" disabled></el-input>
              </el-form-item>

              <el-form-item label="商户类型" class="form-item" prop="merType">
                <el-select v-model="item.merType" disabled>
                  <el-option value="2" label="企业"></el-option>
                  <el-option value="3" label="个体工商户"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="个体工商户/企业名称" class="form-item" prop="companyName">
                <el-input v-model="item.companyName" size="small" disabled></el-input>
              </el-form-item>

              <el-form-item label="个体工商户/企业证件类型" class="form-item" prop="companyCertType">
                <el-select v-model="item.companyCertType" disabled>
                  <el-option value="U" label="营业执照"></el-option>
                  <el-option value="Y" label="组织机构代码证"></el-option>
                  <el-option value="V" label="统一社会信用代码"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="个体工商户/企业证件编号" class="form-item" prop="companyCertNo">
                <el-input v-model="item.companyCertNo" size="small" disabled></el-input>
              </el-form-item>
              <el-form-item label="个体工商户/企业证件照" class="form-item" prop="companyCertNo">
                <el-image style="width: 120px; height: 90px;" :src="item.companyCertPhotoUrl">
                  <div slot="error" class="el-image__error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-form-item>
              <!--              <el-form-item label="商户类别" class="form-item" prop="type">-->
              <!--                <el-select v-model="item.type" disabled>-->
              <!--                  <el-option value="1" label="和上"></el-option>-->
              <!--                  <el-option value="2" label="寺庙"></el-option>-->
              <!--                  <el-option value="3" label="商户"></el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->

              <el-form-item label="商户地址" class="form-item" prop="merAddress">
                <el-input v-model="item.merAddress" size="small" disabled></el-input>
              </el-form-item>

              <el-form-item label="上级商户" class="form-item" prop="parentRate" v-if="item.parentMerchantName">
                <el-input v-model="item.parentMerchantName" size="small" disabled></el-input>
              </el-form-item>

            </el-tab-pane>
            <el-tab-pane label="法人信息" name="personal">
              <el-form-item label="姓名" class="form-item" prop="merContactName">
                <el-input v-model="item.merContactName" size="small" disabled></el-input>
              </el-form-item>

              <el-form-item label="证件类型" class="form-item" prop="certificateType">
                <el-select v-model="item.certificateType" disabled>
                  <el-option value="I" label="身份证"></el-option>
                  <el-option value="P" label="护照"></el-option>
                  <el-option value="G" label="军官证"></el-option>
                  <el-option value="U" label="其他证件"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="证件号" class="form-item" prop="certificateNo">
                <el-input v-model="item.certificateNo" size="small" disabled></el-input>
              </el-form-item>
              <el-form-item label="证件照" class="form-item" prop="companyCertNo">
                <el-image style="width: 120px; height: 90px; margin-right: 10px" :src="item.certificateFrontPhotoUrl">
                  <div slot="error" class="el-image__error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <el-image style="width: 120px; height: 90px;" :src="item.certificateBackPhotoUrl">
                  <div slot="error" class="el-image__error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-form-item>
              <el-form-item label="联系电话" class="form-item" prop="merContactPhone">
                <el-input v-model="item.merContactPhone" size="small" disabled></el-input>
              </el-form-item>
            </el-tab-pane>

            <el-tab-pane label="费率信息" name="rate">
              <el-form-item label="手续费率（%）" class="form-item" prop="rate">
                <el-input v-model="item.rate" size="small" disabled></el-input>
              </el-form-item>

              <el-form-item label="清分账期（天）" class="form-item" prop="intoAccountDuration">
                <el-input v-model="item.intoAccountDuration" size="small" disabled></el-input>
              </el-form-item>
              <el-form-item label="是否自动到账" class="form-item" prop="autoReceipt">
                <el-input v-model="item.autoReceiptLabel" size="small" disabled></el-input>
              </el-form-item>
              <div v-if="item.parentRates">
                <span>分成：</span>
                <el-table :data="item.parentRates" border stripe style="margin-top:10px;">
                  <el-table-column type="index" label="序号" width="250px" header-align="center" align="center"/>
                  <el-table-column prop="merName" label="项目" header-align="center" align="center"/>
                  <el-table-column prop="rate" label="比例(%)" header-align="center" align="center"/>
                </el-table>
              </div>

            </el-tab-pane>

            <el-tab-pane label="银行信息" name="bank">
              <el-form-item label="银行账号" class="form-item" prop="acctNo">
                <el-input v-model="item.acctNo" size="small" disabled></el-input>
              </el-form-item>

              <el-form-item label="开户名" class="form-item" prop="acctName">
                <el-input v-model="item.acctName" size="small" disabled></el-input>
              </el-form-item>

              <el-form-item label="开户行" class="form-item" prop="acctBankName">
                <el-select v-model="item.acctBankName" disabled>
                  <el-option v-for="item in bank_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="开户预留电话" class="form-item" prop="acctPhone">
                <el-input v-model="item.acctPhone" size="small" disabled></el-input>
              </el-form-item>

              <el-form-item label="账户类型" class="form-item" prop="acctCardType">
                <el-select v-model="item.acctCardType" disabled>
                  <el-option value="401" label="个人账户"></el-option>
                  <el-option value="601" label="对公账户"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="辅助材料照片" class="form-item" prop="auxiliaryMaterialsPhotoUrl">
                <el-image style="width: 120px; height: 90px; margin-right: 10px" :src="item.auxiliaryMaterialsPhotoUrl">
                  <div slot="error" class="el-image__error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-form-item>
            </el-tab-pane>

            <el-tab-pane label="账号信息" name="account">
              <el-table :data="item.users" border stripe style="margin-top:10px;">
                <el-table-column type="index" label="序号" width="50" header-align="center" align="center"/>
                <el-table-column prop="username" label="用户名" header-align="center" align="center"/>
              </el-table>
            </el-tab-pane>

            <el-tab-pane label="审核记录" name="checkRecord">

              <el-table :data="checkList" border stripe style="margin-top:10px;">
                <el-table-column type="index" label="序号" width="50px" header-align="center" align="center"/>
                <el-table-column prop="createdAt" label="审核日期" header-align="center" align="center"/>
                <el-table-column prop="message" label="审核描述" header-align="center" align="center"/>
              </el-table>
              <el-pagination style="margin: 10px 0 0 10px;" background :page-size="pageSize" layout="total,prev, pager, next"
                             :total="total" :current-page="currentPage" @current-change="handleCurrentChange"/>
            </el-tab-pane>

          </el-tabs>
        </el-form>

        <el-row style="text-align:center;padding-top: 20px;">
          <router-link :to="{path: `/admin/merchant_verify/index`}">
            <el-button type="button" class="el-button--default">返回</el-button>
          </router-link>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "new.vue",
  data() {
    return {
      fullscreenLoading: false,
      activeName: 'checkRecord',
      item: {
        username: null,
        password: null,
        merLogo: null,
        merName: null,
        merType: null,
        merAddress: null,
        companyCertType: null,
        companyCertNo: null,
        companyCertPhoto: null,
        companyName: null,
        merContactName: null,
        merContactPhone: null,
        certificateType: null,
        certificateNo: null,
        certificateFrontPhoto: null,
        certificateBackPhoto: null,
        acctNo: null,
        acctName: null,
        acctPhone: null,
        acctCardType: null,
        acctBankName: null,
        type: null,
        level: null,
        parentRates: null,
        parentMerchantName: null,
        users: null,
        autoReceipt: null,
        autoReceiptLabel: null,
        intoAccountDuration: null,
        merId:null,
        rate: null
      },
      bank_list: [],
      checkList:[],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      label1: null,
      label2: null,
      label3: null,
      placeholder1: null,
      placeholder2: null,
      placeholder3: null
    }
  },
  mounted() {
    this.item.id = this.$route.params.id
    this.getBankList();
    this.getItem();
  },
  methods: {
    handleCurrentChange(page) {
      this.params.page = page-1
      this.$nextTick(() => {
        this.getList()
      })
    },
    getList(){
      let _this = this
      let params = {
        page: _this.currentPage-1,
        page_size: _this.pageSize,
        merId: _this.item.merId
      }
      _this.fullscreenLoading = true
      adminHttp.post('/backend/merchant/queryMerchantDraftReviewLogList', params).then(result => {
        if (result && result.code === 200) {
          if(result.data!==null){
            _this.checkList = result.data;
            _this.total = result.data.length;
          } else {
            _this.checkList = [];
            _this.total = 0;
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    showChange(value) {
      if (value === '2') {
        this.label1 = '企业名称';
        this.label2 = '企业证件类型';
        this.label3 = '企业证件编号';
        this.placeholder1 = '请输入企业名称';
        this.placeholder2 = '请选择企业证件类型';
        this.placeholder3 = '请输入企业证件编号';
      } else if (value === '3') {
        this.label1 = '个体工商户名称';
        this.label2 = '个体工商户证件类型';
        this.label3 = '个体工商户证件编号';
        this.placeholder1 = '请输入个体工商户名称';
        this.placeholder2 = '请选择个体工商户证件类型';
        this.placeholder3 = '请输入个体工商户证件编号';
      } else {
        this.label1 = "个体工商户/企业名称";
        this.label2 = "个体工商户/企业证件类型";
        this.label3 = "个体工商户/企业证件编号";
        this.placeholder1 = "请输入个体工商户/企业名称";
        this.placeholder2 = "请选择个体工商户/企业证件类型";
        this.placeholder3 = "请输入个体工商户/企业证件编号";
      }
    },
    getItem() {
      let _this = this
      let params = {
        id: _this.item.id
      }
      _this.fullscreenLoading = true
      adminHttp.post('/backend/merchant/querySingleMerchantDraft', params).then(result => {
        if (result.code === 200) {
          _this.item = result.data;
          if (_this.item.autoReceipt == true) {

            _this.item.autoReceiptLabel = "是"
          } else {
            _this.item.autoReceiptLabel = "否"
          }
          _this.item.level = result.data.level ? result.data.level.toString() : '';
          _this.item.type = result.data.type ? result.data.type.toString() : '';
          _this.showChange(_this.item.merType);
          _this.getList();
        } else {
          _this.$message.error(result.message);
        }
      }).catch(error => {
        // console.log('querySingleMerchantDraft error')
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    getBankList() {
      let _this = this
      _this.fullscreenLoading = true
      let params = {
        page: 0,
        page_size: 1000,
      }
      adminHttp.post('/common/getBankList', params).then(result => {
        if (result && result.code === 200) {
          if (result.data !== null) {
            let data = result.data;
            _this.bank_list = data;
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        // console.log('getBankList error')
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>